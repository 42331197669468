import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dashbord from './containers/Dashbord';

const HomePage = (props) => {
    const mHistory = useHistory()
    const user = props.user
    console.log(user)

    useEffect(() => {
      if(!user || user === null){
        return mHistory.push('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    


  return (
    <div className='container_head'>
      <Dashbord />
    </div>
  )
}

const mapStateStore = (stateStore) => {
    return {
      user: stateStore.userState.user,
    };
  };

export default connect(mapStateStore,{})(HomePage)