import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../../../config/keys';
import Select from 'react-select';
import '../css/dashbord.css';
import DownloardPdf from './pdf/DownloardPdf';
// import DownloadPDF from './DownloadPDF';

const Section1 = ({
  stateList,
  formData,
  fromDataError,
  setformData,
  districtList,
  blockList,
  selectedOption,
  setSelectedOption,
  checkIfError,
  onPdfClick,
}) => {
  const [dashbordDetails, setdashbordDetails] = useState({});
  const [staticDashbordDetails, setStaticDashbordDetails] = useState({});
  const [options, setoptions] = useState([]);

  const filterStates = () => {
    let options = [];
    if (stateList.length >= 1) {
      for (let i = 0; i < stateList.length; i++) {
        if (!formData.allState) {
          setformData({ ...formData, district_id: '', districtName: '' });
          let object = {};
          object['value'] = stateList[i]._id;
          object['label'] = stateList[i].name;
          options.push(object);
        }
      }
    }
    if (!formData.state_id[0]) {
      let object = {};
      object['value'] = 'all';
      object['label'] = 'All States';
      options.push(object);
    }

    setoptions(options);
  };
  const selectedOptions2 = (e) => {
    setSelectedOption(e);
    console.log(e);
    let stateId = [];
    let allStates;
    for (let x in e) {
      if (e[x].value === 'all') {
        allStates = true;
        continue;
      } else {
        allStates = false;
      }
      stateId.push(e[x].value);
    }
    console.log({ allStates });
    //  setformData({...formData,allState:allStates})
    setformData({ ...formData, state_id: stateId, allState: allStates });
  };

  useEffect(() => {
    filterStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList, selectedOption]);

  useEffect(() => {
    getDasbordDetails();
    getStaticDasbordDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.state_id, formData.district_id, formData.year]);
  const getDasbordDetails = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      let dashbordData = {
        stateId: formData.state_id,
        districtId: formData.district_id,
        allState: formData.allState,
      };
      const { data } = await axios.post(
        `${configKeys.SERVER_URL}/allDataDashbord`,
        {
          ...dashbordData,
        }
      );
      setdashbordDetails(data.data);
      console.log(data.data);
      const dashbordDatas = JSON.stringify(data.data);
      localStorage.setItem('dashbordSec2', dashbordDatas);
    } catch (error) {
    }
  };

  const getStaticDasbordDetails = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      let districtId;
      if (formData.year === '2021-2022') {
        districtId = '';
      } else districtId = formData.district_id;
      let dashbordData = {
        stateId: formData.state_id,
        allState: formData.allState,
        districtId: districtId,
      };
      const { data } = await axios.post(
        `${configKeys.SERVER_URL}/staticData`,
        dashbordData
      );
      setStaticDashbordDetails(data.data);
      console.log(data.data);
      const dashbordDatas = JSON.stringify(data.data);
      localStorage.setItem('staticData', dashbordDatas);
    } catch (error) {
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
      <div className="">
        <div className="head_container">
          <div className="w-100">
            <div className="head_program">
              Program Summary {'  '}{' '}
              <strong style={{ fontSize: '12px' }}>Since 2015</strong>
            </div>
            <div className="text-center">
              <div className="borderLine"></div>
            </div>
            <div className="d-flex">
              <div
                className="m_r2 stateDecription"
              >
                {' '}
                State : {selectedOption.map((item) => `${item.label} , `)}{' '}
              </div>
              <div className="m_r2 stateDecription">
                {' '}
                {formData.districtName &&
                  ' District :' + formData.districtName}{' '}
              </div>
              <div className="stateDecription">
                {' '}
                {formData.blockName &&
                  ' Block :' + formData.blockName}{' '}
              </div>
            </div>
          </div>
          <div className="text-right w-100">
            <div className="d-flex justify-content-end">
              <div className={'_input_container'}>
                <label className={'_label'}>Select state</label>
                <Select
                  isMulti
                  value={selectedOption}
                  name="colors"
                  options={options}
                  className="basic_multi_select"
                  classNamePrefix="select"
                  onChange={(e) => selectedOptions2(e)}
                />
                {fromDataError.state_id && (
                  <span className="text-danger">{fromDataError.state_id}</span>
                )}
              </div>
              {formData.year === '2022-2023' && (
                <>
                  <div className={'_input_container'}>
                    <label className={'_label'}>Select District </label>
                    <select
                      className={'_input'}
                      style={{ cursor: 'pointer' }}
                      id="year"
                      name="year"
                      disabled={
                        formData.state_id?.length === 1 ||
                        formData.year !== '2021-2022'
                          ? false
                          : true
                      }
                      value={formData.district_id}
                      onChange={(t) => {
                        setformData({
                          ...formData,
                          district_id: t.target.value,
                          districtName: t.target.selectedOptions[0].text,
                        });
                      }}
                    >
                      <option value="" selected>
                        All District
                      </option>
                      {districtList.map((item) => (
                        <option id={item.name} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {/* {formDataError.year && (
                <span className="text-danger">{formDataError.year}</span>
              )} */}
                  </div>
                  <div className={'_input_container'}>
                    <label className={'_label'}>Select Blocks</label>
                    <select
                      className={'_input'}
                      style={{ cursor: 'pointer' }}
                      id="year"
                      name="year"
                      disabled={formData.state_id.length === 0 ? true : false}
                      value={formData.block_id}
                      onChange={(t) => {
                        setformData({
                          ...formData,
                          block_id: t.target.value,
                          blockName: t.target.selectedOptions[0].text,
                        });
                      }}
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {blockList.map((item) => (
                        <option id={item.name} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {/* {formDataError.year && (
<span className="text-danger">{formDataError.year}</span>
)} */}
                  </div>
                </>
              )}
            </div>
            <DownloardPdf formData={formData} onPdfClick={onPdfClick} />
          </div>
        </div>
      </div>
      <div className="dashbord_container">
        <div className="">
          <div className="item_container">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/schoolImpacted.png" alt="users" /> */}
              <div className="itemName">Schools Impacted</div>
            </div>
            <div className="itemHead2">
              {numberWithCommas(
                typeof staticDashbordDetails?.schoolImpacted?.count != 'number'
                  ? 0
                  : staticDashbordDetails?.schoolImpacted?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container backColorL2">
            <div className="itemHead ">
              {/* <img src="/assets/dashbord/childrenImpacted.png" alt="childrenImpacted" /> */}
              <div className="itemName backColor2">Children Impacted</div>
            </div>
            <div className="itemHead2 backColor2">
              {numberWithCommas(
                typeof staticDashbordDetails?.childrenImpacted?.count !=
                  'number'
                  ? 0
                  : staticDashbordDetails?.childrenImpacted?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container backColorL3">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/SSSUsers.png" alt="users" /> */}
              <div className="itemName backColor3">Enrolled Users</div>
            </div>
            <div className="itemHead2 backColor3">
              {' '}
              {numberWithCommas(
                typeof dashbordDetails?.activeSSSUsers?.count != 'number'
                  ? 0
                  : dashbordDetails?.activeSSSUsers?.count +
                      staticDashbordDetails?.SSSUsers?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/sssEnrolledChildren.png" alt="users" /> */}
              <div className="itemName">Enrolled Children</div>
            </div>
            <div className="itemHead2">
              {numberWithCommas(
                typeof dashbordDetails?.activeUsers?.users_registered !=
                  'number'
                  ? 0
                  : dashbordDetails?.activeUsers?.users_registered +
                      staticDashbordDetails?.endrolledChildren?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/sssResourcesUsed.png" alt="users" /> */}
              <div className="itemName">Teaching Resources used</div>
            </div>
            <div className="itemHead2">
              {' '}
              {numberWithCommas(
                (typeof dashbordDetails?.resourcesUsed?.resource_view_count !=
                'number'
                  ? 0
                  : dashbordDetails?.resourcesUsed?.resource_view_count) +
                  (typeof dashbordDetails?.resourcesUsed
                    ?.course_completion_count != 'number'
                    ? 0
                    : dashbordDetails?.resourcesUsed?.course_completion_count) +
                  staticDashbordDetails?.resourcesUsed?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container backColorL2">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/sssKits.png" alt="users" /> */}
              <div className="itemName backColor2">SSS Kits distributed</div>
            </div>
            <div className="itemHead2 backColor2">
              {numberWithCommas(
                typeof staticDashbordDetails?.kitsDistributed?.count != 'number'
                  ? 0
                  : staticDashbordDetails?.kitsDistributed?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container backColorL3">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/teachersTrained.png" alt="users" /> */}
              <div className="itemName backColor3">Teachers trained</div>
            </div>
            <div className="itemHead2 backColor3">
              {numberWithCommas(
                typeof dashbordDetails?.teachersTrained?.visitcount != 'number'
                  ? 0
                  : dashbordDetails?.teachersTrained?.visitcount +
                      staticDashbordDetails?.teacherTrained?.count
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="item_container">
            <div className="itemHead">
              {/* <img src="/assets/dashbord/programAudit.png" alt="users" /> */}
              <div className="itemName">School Visits/Meetings</div>
            </div>
            <div className="itemHead2">
              {' '}
              {numberWithCommas(
                (typeof dashbordDetails?.programAuditCount?.visitcount !=
                'number'
                  ? 0
                  : dashbordDetails?.programAuditCount?.visitcount) +
                  (typeof dashbordDetails?.schoolVisitsCount?.visitcount !=
                  'number'
                    ? 0
                    : dashbordDetails?.schoolVisitsCount?.visitcount) +
                  staticDashbordDetails?.programMeeting?.count
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;

// <div className={'_input_container'}>
// <label className={'_label'}>Select Blocks</label>
// <select
//   className={'_input'}
//   style={{ cursor: 'pointer' }}
//   id="year"
//   name="year"
//   disabled={formData.state_id.length === 0 ? true : false}
//   value={formData.block_id}
//   onChange={(t) => {
//     setformData({
//       ...formData,
//       block_id: t.target.value,
//       blockName: t.target.selectedOptions[0].text,
//     });
//   }}
// >
//   <option value="" selected >
//     Select
//   </option>
//   {blockList.map((item) => (
//     <option id={item.name} value={item._id}>{item.name}</option>
//   ))}
// </select>
// {/* {formDataError.year && (
// <span className="text-danger">{formDataError.year}</span>
// )} */}
// </div>
