import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
// import inputValidation from '../constants/inputValidaation';
//import "./css/progressSmall.css"
import "./css/progress.css"
//import "./css/progressSmall2.css" 
import "./css/dashboardstyle.css"
import { Link, useLocation } from 'react-router-dom';
import LoadingSpinner from "../components/LoadingSpinner";
import SchoolPerformance from "../components/SchoolPerformance";

// import "./css/style.scss"
import DownloardPdf from './pdf/DownloardPdf';
const SchoolDashboard = () => {
  const location = useLocation().search
  const state_id = new URLSearchParams(location).get("state_id")
  const district_id = new URLSearchParams(location).get("district_id")
  const block_id = new URLSearchParams(location).get("block_id")
  const dise_code = new URLSearchParams(location).get("school")
  const currentSession = new URLSearchParams(location).get("session")
  const [isLoading, setIsLoading] = useState(false);
  
  const [totalDashbord, setTotalDashbord] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    getSchoolPerformance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSchoolPerformance = async () => {
    setIsLoading(true);
    try {
      let localData = {
        state_id: state_id,
        district_id: district_id,
        block_id: block_id,
        dise_code: dise_code,
        session: currentSession
      }
      const value = JSON.stringify(localData)
      localStorage.setItem('SDB_id',value)
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v2/schoolPerformance`,localData);
      setTotalDashbord(data.data);
      setSchoolData(data.data.schoolData)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
	    {/* <!-- Header --> */}

        {isLoading ? '' : (
        <div className="row selections">
            <nav className="navbar navbar-expand-lg m-0 pb-2 pt-2 p-4" style={{justifyContent:'flex-end',backgroundColor:'#eeeeee'}}>
            <div className="d-flex align-items-right">
            <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav ms-auto">
    { <li className="d-flex">
    <DownloardPdf state={state_id} district={district_id} block={block_id} school={schoolData.disecode} session={currentSession}  />    </li>}
            </ul>
            </div>
            </div>
            </nav>   
        </div>
        )}
        
        {isLoading ? <LoadingSpinner /> : <SchoolPerformance schoolData={schoolData} totalDashbord={totalDashbord} selectedSession={currentSession} />} 
    </>
  )
}

export default SchoolDashboard