import React from "react";
import ProgressGraph from "../components/ProgressGraph";
import RatioGraph from "../components/RatioGraph";

import logo from   "./images/logo-black.svg"
import star from "./images/star.png"
import estar from "./images/Estar.png"
import hstar from "./images/Hstar.png"
import iconv from "./images/iconv.png"

const SchoolDashboard = (props) => {
    const selectedSession = (props.selectedSession) ? props.selectedSession : "";
    const schoolData = (props.schoolData) ? props.schoolData : [];
    const totalDashbord = (props.totalDashbord) ? props.totalDashbord : "";
    
    function DateDisplay() {
        let makeDate = new Date();
        const currYear = makeDate.getFullYear();
        let startMonth = 'April';
        let currentSession = currYear+'-'+(currYear+1)
        console.log("currentSession : ",currentSession)
        console.log("selectedSession : ",selectedSession)
        if (currentSession === selectedSession) {
            let startYear = currentSession.split("-")[0]
            const currDay = makeDate.getDate();
            let currMonth = ''
            if (currDay >= 30) {
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            } else {
                makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            }
            if (currMonth === startMonth) {
                return <p className="duration">{'April '+startYear}</p>;
            } else {
                return <p className="duration">{'April '+startYear+' - '+currMonth+' '+currYear}</p>;
            }
        } else {
            return <p className="duration">{'April '+selectedSession.split("-")[0]+' - March '+selectedSession.split("-")[1]}</p>;
        }
    }

    const returnStar = (length) => {
        if (length === 0) {
          return (
            <>
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if(length <= 0.5) {
            return (
              <>
                <img src={hstar} alt="hstar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 1) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 1.5) {
            return (
              <>
                 <img src={star} alt="star"  />
                 <img src={hstar} alt="hstar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 2) {
          return (
            <>
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 2.5) {
          return (
            <>
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={hstar} alt="hstar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 3) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 3.5) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={hstar} alt="hstar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 4) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 4.5) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={hstar} alt="hstar"  />
              </>
            );
          }else if (length <= 5) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
              </>
            );
          }
      };
      
    const districtDashboard = (
        <div>
            {/* <!-- Block Section --> */}
            <div className="row bg-sec">
            <div className="col-md-3 d-flex pt-4 pb-4" style={{justifyContent:'space-evenly'}}>
                <div className="bar-item m-4">
                    <h1 className="bold statename">{schoolData?.name}</h1>
                    <p className="bold statename">(DISE CODE : {schoolData?.disecode})</p>
                </div>
            </div>
            <div className="col-md-9 d-flex pt-4 pb-4" style={{justifyContent:'space-evenly'}}>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{schoolData?.sssKitsDonated}</h2>
                    <p className="bold">SSS Kits<br></br>Distributed</p>
                </div>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{schoolData?.noOfSTV}</h2>
                    <p className="bold">Sampark TVs<br></br>Distributed</p>
                </div>
                <div className="bar-item mb-4 mt-4">
                    <h2 className="bold">{totalDashbord?.trainedTeachers} / {totalDashbord?.totalTrainedTeachers}</h2>
                    <p className="bold">Teachers Trained<br></br>YTD / Till Now</p>
                </div>
            </div>
        </div>

            {/* <!-- First Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-1 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">1. TEACHERS<br />FEEDBACK</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body p-4 mb-4">
                                    <div className="row">
                                        <div className="col-lg-4 d-flex justify-content-center">
                                            <div className="rating-card-start">
                                                <div className="rating-text">
                                                    <div className="rating-heading">
                                                        Rating
                                                    </div>
                                                    <div className="rating-number">
                                                    {`${parseFloat(totalDashbord?.teacherFeedback?.overall_rating?.toFixed(2))}`}
                                                    </div>                        
                                                </div>
                                                <div className="ratings">
                                                {returnStar((totalDashbord?.teacherFeedback?.overall_rating))}
                                                    {/* <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star"></i> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                        <p className="m-0">SMS Feedback by <b>{`${parseFloat(totalDashbord?.teacherFeedback?.overall_feedback?.toFixed(2))}`}</b> teachers</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Second Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-3 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">2. TEACHING AS PER ACADEMIC PLAN - % OF SCHOOLS</p>
                        </div>

                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <div className="row mb-2 mt-4">
                                        <div className="col-md-6 ct align-items-center br justify-content-center">
                                            <ProgressGraph width={250} progress={parseFloat((totalDashbord?.samparkTVData?.tv_utilization?.class15_average || 0).toFixed(2))}/>
                                            <p className="m-0 bold themecolor small">Class 1-5</p>
                                        </div>
                                        <div className="col-md-6 ct align-items-center justify-content-center">
                                            <ProgressGraph width={250} progress={parseFloat(totalDashbord?.samparkTVData?.tv_utilization?.class68_average?.toFixed(2))} isNA={totalDashbord?.upperPrimarySTVSchools === 0 ? 'YES' : 'NO'}/>
                                            <p className="m-0 bold themecolor small">Class 6-8</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 ct align-items-center justify-content-center">
                                            <p className="m-0 bold small">% of Lessons Completed</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                            <p className="m-0">Sampark TV usage data uploaded by teachers & physical visits to schools
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- THIRD Section --> */}
            <div className="row datasection">
                <div className="col-md-2 left-bg-5 d-flex align-items-center justify-content-center mb-4">
                    <div className="side-l-t p-4">
                        <div className="side-l-t-content white ct">
                            <p className="bold">3. TEACHERS REVIEW LESSONS BEFORE TEACHING IN CLASS</p>
                        </div>

                    </div>
                </div>
                <div className="col-md-10 mb-4">
                    <div className="card shadow">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 ct align-items-center br justify-content-center">
                                        <ProgressGraph width={250} progress={(50)}/>
                                            <p className="m-0 bold themecolor small">Teachers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 ct align-items-center br justify-content-center">
                                            <RatioGraph width={250} type={'classwise'} class15progress={parseFloat(totalDashbord?.resourceUsageFLN)} class68progress={parseFloat(totalDashbord?.resourceUsage68)} />
                                            <p className="m-0 bold themecolor small">Resource Usage</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer white bg-sec ct pb-2 pt-2">
                            <p className="m-0">1/2 Teachers follow "Sahi Kram Sahi Dhang"</p>
                        </div>
                    </div>
                </div>
            </div>            
        
        </div>
    )
    return (
        <div className="printme">
            <div className="row">
                <div className="col-md-6 d-flex pt-2 pb-2" style={{justifyContent:'left'}}>
                    <div className="bar-item m-4">
                        <img src={logo} alt="Logo" width={250} />
                    </div>
                </div>
                <div className="col-md-6 d-flex pt-2 pb-2" style={{justifyContent:'right'}}>
                    <div className="bar-item m-4">
                        <p className="red district">District - {schoolData?.district}, Block - {schoolData?.block}</p>
                        {DateDisplay()}
                    </div>
                </div>
            </div>
            {districtDashboard}
        </div>
    );
}

export default SchoolDashboard
