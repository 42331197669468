import React from 'react';
import '../css/dashbord.css';
const Topdistrict = ({ topBlocks, topDistrict, formData, selectedOption }) => {

  return (
    <>
      <div className="head_container">
        <div className="w-100">
          <div className="head_program">
            {' '}
            B. Program Benchmark On Resources Used/School
          </div>
          <div className="d-flex">
            <div style={{ marginRight: '30px' }} className="mr-3">
               State : {selectedOption.map((item)=>(`${item.label} , ` ))} 
            </div>
            <div className=''> {formData.districtName && ' District :'+ formData.districtName  } </div>
          </div>
        </div>
      </div>
      <div className="dashbord_container ">
        {!formData.district_id && (
          <>
            <div className="item_container">
              <div className="itemHead">
                {/* <img className='_blockImg' src="/assets/dashbord/district.png" alt="block" /> */}
                <div className="itemName">Top 5 Districts (1-5)</div>
              </div>
              <div className="itemHead2">
                {topDistrict?.length >= 1 &&
                  topDistrict.slice(0,5)?.map((item) => (
                    <div className="_district">{item.districtName}</div>
                  ))}
                {topDistrict?.length === 0 && (
                  <div className="_district">No District</div>
                )}
              </div>
            </div>

            <div className="item_container">
              <div className="itemHead">
              {/* <img className='_blockImg' src="/assets/dashbord/district.png" alt="block" /> */}
                <div className="itemName">Next 5 Districts (6-10)</div>
              </div>
              <div className="itemHead2">
                {topDistrict?.length >= 1 &&
                 topDistrict.slice(5,10)?.map((item) => (
                    <div className="_district">{item.districtName}</div>
                  ))}
                {topDistrict?.length === 0 && (
                  <div className="_district">No District</div>
                )}
              </div>
            </div>

            <div className="item_container">
              <div className="itemHead">
              {/* <img className='_blockImg' src="/assets/dashbord/district.png" alt="block" /> */}
                <div className="itemName">Next 5 Districts (11-{topDistrict.length >= 16 ? 15 : topDistrict.length})</div>
              </div>
              <div className="itemHead2">
                {topDistrict?.length >= 11 &&
                   topDistrict.slice(10,15)?.map((item) => (
                    <div className="_district">{item.districtName}</div>
                  ))}
                {topDistrict.length === 0 && (
                  <div className="_district">No District</div>
                )}
              </div>
            </div>
            {topDistrict.length >= 16 && (
               <div className="item_container">
               <div className="itemHead">
               {/* <img className='_blockImg' src="/assets/dashbord/district.png" alt="block" /> */}
                 <div className="itemName">Next 5 Districts (16-{topDistrict.length -1})</div>
               </div>
               <div className="itemHead2">
                 {topDistrict.length >= 16 &&
                   topDistrict.slice(15,21)?.map((item) => (
                     <div className="_district">{item.districtName}</div>
                   ))}
                 {topDistrict.length === 0 && (
                   <div className="_district">No District</div>
                 )}
               </div>
             </div>
            )}
           
          </>
        )}
        {formData.district_id && (
          <>
            <div className="item_container">
              <div className="itemHead">
              {/* <img className='_blockImg' src="/assets/dashbord/block.png" alt="block" /> */}
                <div className="itemName">Top 5 Blocks (1-5)</div>
              </div>
              <div className="itemHead2">
              {topBlocks?.length >= 1 &&
                  topBlocks.slice(0,5)?.map((item) => (
                    <div className="_district">{item.blockName}</div>
                  ))}
                {topBlocks?.length === 0 && (
                  <div className="_district">No District</div>
                )}
              </div>
            </div>
           {topBlocks.length >= 6 && (
             <div className="item_container">
             <div className="itemHead">
             {/* <img className='_blockImg' src="/assets/dashbord/block.png" alt="block" /> */}
               <div className="itemName">Next 5 Blocks (6-{topBlocks.length >= 9 ? 10 : topBlocks.length})</div>
             </div>
             <div className="itemHead2">
             {topBlocks?.length >= 1 &&
                topBlocks.slice(5,10)?.map((item) => (
                   <div className="_district">{item.blockName}</div>
                 ))}
               {topBlocks?.length === 0 && (
                 <div className="_district">No Block</div>
               )}
             </div>
           </div>
           )}
            {topBlocks.length >= 11 && (
                          <div className="item_container">
                          <div className="itemHead">
                          {/* <img className='_blockImg' src="/assets/dashbord/block.png" alt="block" /> */}
                            <div className="itemName">Next 5 Blocks (11-{topBlocks.length >= 16 ? 15 : topBlocks.length})</div>
                          </div>
                          <div className="itemHead2">
                          {topBlocks?.length >= 11 &&
                               topBlocks.slice(10,15)?.map((item) => (
                                <div className="_district">{item.blockName}</div>
                              ))}
                            {topBlocks.length === 0 && (
                              <div className="_district">No Block</div>
                            )}
                          </div>
                        </div>
            )}

            {topBlocks.length >= 16 && (
               <div className="item_container">
               <div className="itemHead">
               {/* <img className='_blockImg' src="/assets/dashbord/district.png" alt="block" /> */}
                 <div className="itemName">Next 5 Blocks (16-{topBlocks.length -1})</div>
               </div>
               <div className="itemHead2">
                 {topBlocks.length >= 16 &&
                   topBlocks.slice(15,21)?.map((item) => (
                     <div className="_district">{item.blockName}</div>
                   ))}
                 {topBlocks.length === 0 && (
                   <div className="_district">No Block</div>
                 )}
               </div>
             </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Topdistrict;
