
import './App.css';
import {Route,Switch } from "react-router-dom"
import HomePage from './pages/HomePage';
import Register from './pages/Register';
import Preview from './pages_v2/Preview';
import Dashboard from "./pages_v2/Dashboard"
import SchoolDashboard from "./pages_v2/SchoolDashboard"
import TopBlocks from './pages_v2/TopBlocks';
import TopSchools from './pages_v2/TopSchools';
import '@progress/kendo-theme-default/dist/all.css';
function App() {
  return (
    <div className="App">
      {/* <Navbar/> */}
     
      <div>
      <Switch>
        <Route exact  path='/' component={Dashboard}  />
        <Route exact path='/topBlocks' component={TopBlocks}  />
        <Route exact path='/schoolList' component={TopSchools}  />
        <Route exact path='/register' component={Register}  />
        <Route exact path='/previewPdf' component={Preview}  />
        <Route exact path='/schoolPerformance' component={SchoolDashboard}  />
        <Route  path='*' component={HomePage}  />
      </Switch>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
        integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
        crossOrigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js"
        integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V"
        crossOrigin="anonymous"></script>
    </div>
  );
}

export default App;
