import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../../config/keys';
import inputValidation from '../../constants/inputValidaation';
import './css/dashbord.css';
import Section1 from './dashbord/Section1';
import Section2 from './dashbord/Section2';
// import Section3 from './dashbord/Section3';
const Dashbord = () => {
  const [Loading, setLoading] = useState({
    loading: false,
    success: '',
    error: '',
  });
  const [selectedOption, setSelectedOption] = useState([
    { value: 'all', label: 'All States' },
  ]);
  const [dashbordDetails, setdashbordDetails] = useState('');
  const [topDistrict, setTopDistrict] = useState([]);
  const [topBlocks, setTopBlocks] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [formData, setformData] = useState({
    year: '2022-2023',
    month: '',
    state_id: [],
    district_id: '',
    districtName: '',
    block_id: '',
    blockName: '',
    frequency: '',
    allState: true,
  });
  const [fromDataError, setFromDataError] = useState({
    year: '',
    month: '',
    state_id: '',
    district_id: '',
    frequency: '',
  });

  useEffect(() => {
    getStateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDasbordDetails();
    if (formData.state_id.length >= 1) {
      // getTopDistrict();
      // getTopBlocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.state_id, formData]);

  useEffect(() => {
    if (formData.state_id.length >= 1) {
      getDistrictList(
        formData.state_id[0] !== '' || formData.state_id[0] !== 'all'
          ? formData.state_id[0]
          : '5e3954011a2ed23b0ca82b10'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.state_id]);

  useEffect(() => {
    if (formData.state_id.length >= 1 && formData.district_id) {
      getBlockList(formData.district_id,
        formData.state_id[0] !== '' || formData.state_id[0] !== 'all'
          ? formData.state_id[0]
          : '5e3954011a2ed23b0ca82b10'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.state_id,formData.district_id]);

  const getDasbordDetails = async () => {
    try {
      if (checkIfError()) {
        return;
      }
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      let dashbordData = {
        state_id: formData.state_id,
        year: formData.year,
        month: formData.month,
        frequency: formData.frequency,
        allState: formData.allState,
      };
      if (formData.district_id) {
        dashbordData['district_id'] = formData.district_id;
      }
      if (formData.block_id) {
        dashbordData['block_id'] = formData.block_id;
      }
      const { data } = await axios.post(
        `${configKeys.SERVER_URL}/YearDashbord`,
        {
          ...dashbordData,
        }
      );
      setdashbordDetails(data.data);
      console.log(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };

  // eslint-disable-next-line
  const getTopDistrict = async () => {
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      let districtData = {
        state_id:
          formData.state_id[0] === 'all' || formData.state_id[0] === ''
            ? '5e3954011a2ed23b0ca82b10'
            : formData.state_id[0],
        month: formData.month,
        year: formData.year,
        frequency: formData.frequency,
      };
      const { data } = await axios.post(
        `${configKeys.SERVER_URL}/topDistrict`,
        { ...districtData }
      );
      let district = [];
      district['district1'] = [];
      district['district2'] = [];
      district['district3'] = [];

      if (data.data.length > 0) {
        district['district1'] = data.data.slice(1, 6);
        district['district2'] = data.data.slice(6, 11);
        district['district3'] = data.data.slice(11, 16);
        district['district4'] = data.data.slice(16, 21);
      }

      setTopDistrict(district);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };
  // eslint-disable-next-line
  const getTopBlocks = async () => {
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      let blocksData = {
        state_id:
          formData.state_id[0] === 'all' || formData.state_id[0] === ''
            ? '5e3954011a2ed23b0ca82b10'
            : formData.state_id[0],
        month: formData.month,
        year: formData.year,
      };

      const { data } = await axios.post(`${configKeys.SERVER_URL}/topBlocks`, {
        ...blocksData,
      });
      let blocks = [];
      blocks['blocks1'] = [];
      blocks['blocks2'] = [];
      if (data.data?.length > 0) {
        blocks['blocks1'] = data.data.slice(1, 6);
        blocks['blocks2'] = data.data.slice(6, 11);
        blocks['blocks3'] = data.data.slice(11, 16);
        blocks['blocks4'] = data.data.slice(16, 21);
      }

      setTopBlocks(blocks);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };
  const getDistrictList = async (stateId) => {
    try {
      if (checkIfError()) {
        return;
      }
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      const { data } = await axios(
        `${configKeys.SERVER_URL}/getDistrict?stateId=${stateId}`
      );
      setDistrictList(data.data);
      console.log(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };
  const getBlockList = async (districtId , stateId) => {
    try {
      if (checkIfError()) {
        return;
      }
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      const { data } = await axios(
        `${configKeys.SERVER_URL}/getBlocks?stateId=${stateId}&districtId=${districtId}`
      );
      setBlockList(data.data);
      console.log(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };

  const getStateList = async () => {
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });

      const { data } = await axios(`${configKeys.SERVER_URL}/getStates`);
      setstateList(data.data);
      console.log(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };
  const onPdfClick = ()=>{
    const dashbordDatas = JSON.stringify(dashbordDetails)
    localStorage.setItem('dashbordSec1',dashbordDatas);
    const formDatas = JSON.stringify(formData)
    localStorage.setItem('formData',formDatas);
  }

  const checkIfError = () => {
    let hasError = false; // False means the form has no error and it can be submitted
    let tempError = {
      state_id: '',
    };
    if (inputValidation.isInputHasLength(formData.state_id) !== '') {
      tempError.state_id = inputValidation.isInputHasLength(formData.state_id);
      if (formData.allState) {
        hasError = false;
        tempError.state_id = ''
      } else hasError = true;
    }
    setFromDataError({
      ...fromDataError,
      ...tempError,
    });
    console.log({ hasError });
    return hasError;
  };



  return (
    <div className="container ">
      <div className="col-12 row">
        <div className="col-12 col-lg-12">
          <Section1
          onPdfClick={onPdfClick}
            checkIfError={checkIfError}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            formData={formData}
            setformData={setformData}
            topDistrict={topDistrict}
            topBlocks={topBlocks}
            dashbord={dashbordDetails}
            districtList={districtList}
            blockList={blockList}
            fromDataError={fromDataError}
            stateList={stateList}
          />

          {Loading.loading && (
            <div className="text-center py-5">
              <div className="spinner-border text-primary" role="status" />
            </div>
          )}
          {!Loading.loading && dashbordDetails && (
            <Section2
              formData={formData}
              setformData={setformData}
              topDistrict={topDistrict}
              topBlocks={topBlocks}
              dashbord={dashbordDetails}
              districtList={districtList}
              selectedOption={selectedOption}
              
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashbord;
