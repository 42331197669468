import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import configKeys from '../config/keys';
import { PDFExport } from '@progress/kendo-react-pdf';
// import inputValidation from '../constants/inputValidaation';
import "./css/progressSmall2.css"
import "./css/style.css"
import { useLocation } from 'react-router-dom';
import LoadingSpinner from "../components/LoadingSpinner";
import MainDashboard from "../components/MainDashboard";
import BlockDashboard from "../components/BlockDashboard";

const DMDashboardPreview = () => {
    const pdfExportComponent = useRef(null);
    const pdfExportComponent2 = useRef(null);
    const location = useLocation().search
    const state_id = new URLSearchParams(location).get("state")
    const district_id = new URLSearchParams(location).get("district")
    const block_id = new URLSearchParams(location).get("block")
    const session = new URLSearchParams(location).get("session")
    const [isLoading, setIsLoading] = useState(false);
    const [upperPrimaryStatus, setupperPrimaryStatus] = useState('')
    
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
        }
    };
    const exportPDFWithComponent2 = () => {
        if (pdfExportComponent2.current) {
        pdfExportComponent2.current.save();
        }
        axios.post(`${configKeys.SERVER_URL}/v2/eventtracking`,
        {
            state_id:state_id,
            district_id:district_id,
            block_id:block_id,
            session:session,
            event:'download'
        }
        );
    };
    const pdfHandeller = ()=>{
        exportPDFWithComponent()
        setTimeout(() => {
            exportPDFWithComponent2()
        }, 3000);
    }
    const [printSecondPage, setprintSecondPage] = useState([]);
    const [currentDistrict, setcurrentDistrict] = useState([]);
    const [currentBlock, setcurrentBlock] = useState([]);
    const [currentState, setcurrentState] = useState([]);
    const [districtAverage, setdistrictAverage] = useState('');
    const [topBlocks, setTopBlocks] = useState([])
    const [topSchools, setTopSchools] = useState([])
    const [totalDashbord, setTotalDashbord] = useState([]);
	
    const [topBlocksLoading, setTopBlocksLoading] = useState({
        loading: false,
        success: '',
        error: '',
    })
    useEffect(() => {
        getDistrictDetail(district_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id])
    useEffect(() => {
        if (block_id) {
            getBlockDetail(block_id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id, district_id])
	
    function FileName() {
        let makeDate = new Date();
        const currDay = makeDate.getDate();
        if (currDay < 25) {
            makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
        }
        const currYear = makeDate.getFullYear();
        return `${makeDate.toLocaleString('default', { month: 'long' })} ${currYear}`
    }
    
    const  getBlocks = async ()=>{
        try {
            setIsLoading(true);
            setTopBlocksLoading({
                loading: true,
                success: '',
                error: '',
              });
              const { data } = await axios.get(`${configKeys.SERVER_URL}/v2/topBlockList?state_id=${state_id}&district_id=${district_id}&session=${session}`);
              setTopBlocks(data.data.topBlocks);
              setTopSchools(data.data.topSchools);
              setdistrictAverage(data.data.average)
              setupperPrimaryStatus(data.data.upperPrimarySTVSchools)
              setTopBlocksLoading({
                loading: false,
                success: '',
                error: '',
              });
              setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setTopBlocksLoading({
                    loading: false,
                    success: '',
                    error: error.message,
                });
            }
    }

    const getDistrictDetail = async (districtId) => {
        try {
            setIsLoading(true);
            const { data } = await axios(
                `${configKeys.SERVER_URL}/getDistrictDetail?districtId=${districtId}`
            );
            setcurrentDistrict("District "+data.data["name"].toLowerCase())
            setIsLoading(false);
            getTotalYearDashbordPreview()
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getBlockDetail = async (blockId) => {
        try {
            setIsLoading(true);
            const { data } = await axios(
                `${configKeys.SERVER_URL}/getBlockDetail?blockId=${blockId}`
            );
            setcurrentBlock(" - Block "+data.data["name"].toLowerCase())
            setIsLoading(false);
            getTotalYearDashbordPreview()
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getTotalYearDashbordPreview = async () => {
        try {
            setIsLoading(true);
            const localData = {
                state_id:state_id,
                district_id:district_id,
                block_id:block_id,
                session:session
            }
            const value = JSON.stringify(localData)
            localStorage.setItem('SDB_id',value)
            const { data } = await axios.post(`${configKeys.SERVER_URL}/v2/totalDashbord`,
            {
                state_id:state_id,
                district_id:district_id,
                block_id:block_id,
                session:session
            }
            );
            setTotalDashbord(data.data);
            if (block_id !== '') {
                setprintSecondPage("0")
            } else {
                (data.data?.samparkTVData?.tv_utilization?.overall_average === 0) ? setprintSecondPage("0") : setprintSecondPage("1")                
            }
            setcurrentState(data.data["stateData"]["name"])
            await getBlocks()
            
            //setIsLoading(false);

            await axios.post(`${configKeys.SERVER_URL}/v2/eventtracking`,
                {
                    state_id:state_id,
                    district_id:district_id,
                    block_id:block_id,
                    session:session,
                    event:'preview'
                }
            );
        } catch (error) {
            setIsLoading(false);
        }
    };


  return (
    <>  
    <div className='text-center'>
    </div>
        {isLoading ? <LoadingSpinner /> : (
        <>
        <div className="white ct mt-4 pt-4">
        <button
          // style={{"marginTop"}}
          // ref={pdfExportComponent}
          onClick={pdfHandeller}
          className="btn btn-outline-success px-2 mt-3 preview_btn"
          //   document={<PdfDocument data={movieDetails} />}
          filename="dashboard.pdf"
          >
          DOWNLOAD PDF ⬇️
        </button>
        </div>
          {/* <!-- Header --> */}
        
        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          forcePageBreak=".page-break"
          keepTogether='div'
          scale={0.3}
          // paperSize="A5"
          //margin={'2cm'}
          fileName={`${currentDistrict} ${currentBlock} REPORT FOR ${FileName()}`} 
          author="Sampark Foundation"
        >
        <section>

        {isLoading ? <LoadingSpinner /> : <MainDashboard currentDistrict={currentDistrict} currentBlock={currentBlock} stateName={currentState} totalDashbord={totalDashbord} selectedSession={session} />} 

        </section>
        
        {printSecondPage === "0" ? '' : (
        <section>            
            {isLoading ? '' : <BlockDashboard 
                currentDistrict={currentDistrict} 
                districtAverage={districtAverage} 
                topBlocks={topBlocks} 
                topSchools={topSchools}
                state_id={state_id}
                selectedSession={session}
                upperPrimaryStatus={upperPrimaryStatus}
                topBlocksLoading={topBlocksLoading} />} 

        </section>
        )}
        </PDFExport>
        </>
        )}

    </>
  )
}

export default DMDashboardPreview
