import React, { useEffect, useState } from 'react';
import configKeys from '../../../config/keys';
import axios from 'axios';
import '../css/dashbord.css';
import '../css/circleRadius.css';
import Topdistrict from './Topdistrict';

const Section2 = ({
  dashbord,
  formData,
  setformData,
  // topDistrict,
  districtList,
  topBlocks,selectedOption
}) => {
  const [staticYearData, setStaticYearData] = useState({})
  const [topDistrict, setTopDistrict] = useState('')
  const [topBlock, setTopBlock] = useState('')
  const [dashbordDataSchool, setdashbordDataSchool] = useState('')
  const [Loading, setLoading] = useState({
    loading: false,
    success: '',
    error: '',
  })

  const rating = (complate, total) => {
    if (0 === parseInt(total)) return 0;
    if (0 === parseInt(complate)) return 0;
    else return parseFloat(((parseInt(complate) / parseInt(total)) * 5).toFixed(2));
  };
  const percentage = (complate, total) => {
    if (0 === parseInt(total)) return 0;
    if (0 === parseInt(complate)) return 0;
    else return parseFloat(((parseInt(complate) / parseInt(total)) * 100).toFixed(2));
  };
  const ratingSSS = (complate, total) => {
    if (0 === parseInt(total)) return 0;
    if (0 === parseInt(complate)) return 0;
    else return parseFloat(((parseInt(complate) / parseInt(total)) * 100).toFixed(2));
  };

  function numberWithCommas(x) {
    if(x === undefined || x === null) return x
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return x
}
useEffect(() => {
  getStaticDasbordDetails()
  getSchoolImpacted()
  if(!formData.allState && !formData.district_id){
    getTopDistrict()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [formData.state_id])

useEffect(() => {
  if(!formData.allState && formData.district_id ){
    getTopBlocks()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [formData.state_id])

// useEffect(() => {
//   if(!formData.allState){
//     if(formData.state_id.length === 1 ){
//       getStaticTopDistrict(formData.state_id[0])
//     }
//   }
// // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [formData.state_id])

const getStaticDasbordDetails = async () => {
  try {
  
    setLoading({
      loading: true,
      success: '',
      error: '',
    });
    let dashbordData = {
      stateId:formData.state_id,
      allState:formData.allState,
      year:formData.year
    }
    const { data } = await axios.post(`${configKeys.SERVER_URL}/year/staticData`,dashbordData);
    setStaticYearData(data.data);
    console.log(data.data);
    const dashbordDatas = JSON.stringify(data.data)
    localStorage.setItem('staticYearData',dashbordDatas);
    setLoading({
      loading: false,
      success: '',
      error: '',
    });
  } catch (error) {
    setLoading({
      loading: false,
      success: '',
      error: error.message,
    });
  }
};
const getSchoolImpacted = async (state_id) => {
  try {
  
    setLoading({
      loading: true,
      success: '',
      error: '',
    });
    let dashbordData = {
      stateId:formData.state_id,
      districtId:formData.district_id,
      allState:formData.allState
    }
    const { data } = await axios.post(`${configKeys.SERVER_URL}/state/schoolImpacted`,dashbordData);
    setdashbordDataSchool(data.data);
    console.log(data.data);
    setLoading({
      loading: false,
      success: '',
      error: '',
    });
  } catch (error) {
    setLoading({
      loading: false,
      success: '',
      error: error.message,
    });
  }
};
const getTopDistrict = async () => {
  try {
  
    setLoading({
      loading: true,
      success: '',
      error: '',
    });
    let dashbordData = {
      state_id:formData.state_id,
      district_id:formData.district_id,
      year:"2022"
    }
    const { data } = await axios.post(`${configKeys.SERVER_URL}/topDistrict`,dashbordData);
    setTopDistrict(data.data);
    console.log(data.data);
    setLoading({
      loading: false,
      success: '',
      error: '',
    });
  } catch (error) {
    setLoading({
      loading: false,
      success: '',
      error: error.message,
    });
  }
};
const getTopBlocks = async () => {
  try {
  
    setLoading({
      loading: true,
      success: '',
      error: '',
    });
    let dashbordData = {
      state_id:formData.state_id,
      district_id:formData.district_id,
      year:"2022"
    }
    const { data } = await axios.post(`${configKeys.SERVER_URL}/topBlocks`,dashbordData);
    setTopBlock(data.data);
    console.log(data.data);
    setLoading({
      loading: false,
      success: '',
      error: '',
    });
  } catch (error) {
    setLoading({
      loading: false,
      success: '',
      error: error.message,
    });
  }
};
useEffect(() => {
  if(formData.year === "2021-2022" && formData.month !== "" ){
    setformData({
      ...formData,
      month: "",frequency:""
    });
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [formData.year])

  const months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];
  return (
    <>
      <div className="col-12">
        <div className="head_container mt-5">
        <div className='w-100' > 
            <div className="head_program">Overall Program Highlights </div>
            <div className='text-center'>
              <div className='borderLine' ></div>
            </div>
            <div className='d-flex' >
            <div  className='m_r2 stateDecription'> State : {selectedOption.map((item)=>(`${item.label} , ` ))}  </div>
            <div className='m_r2 stateDecription'> {formData.districtName && ' District :'+ formData.districtName  } </div>
            <div className='stateDecription'> {formData.blockName && ' District :'+ formData.blockName  } </div>
            </div>
          </div>
          <div className="text-right w-100">
            <div className="d-flex justify-content-end">
              <div className={'_input_container'}>
                <label className={'_label'}>Select year</label>
                <select
                  className={'_input'}
                  style={{ cursor: 'pointer' }}
                  id="year"
                  name="year"
                  value={formData.year}
                  onChange={(t) => {
                    setformData({
                      ...formData,
                      year: t.target.value,
                    });
                  }}
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {/* <option value="2020-2021">20-21</option> */}
                  <option value="2021-2022">2021-2022</option>
                  <option value="2022-2023">2022-2023</option>
                </select>
                {/* {formDataError.year && (
                <span className="text-danger">{formDataError.year}</span>
              )} */}
              </div>
              <div className={'_input_container'}>
                <label className={'_label'}>Select Month </label>
                <select
                  className={'_input'}
                  style={{ cursor: 'pointer' }}
                  id="month"
                  name="month"
                  disabled={formData?.frequency || formData.year === "2021-2022" ? true : false}
                 
                  value={formData.month}
                  onChange={(t) => {
                    setformData({
                      ...formData,
                      month: t.target.value,
                    });
                  }}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {months.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </select>
                {/* {formDataError.month && (
                                        <span className="text-danger">
                                            {formDataError.month}
                                        </span>
                                    )} */}
              </div>
              <div>OR</div>
              <div className={'_input_container'}>
                <label className={'_label'}>Select Quater </label>
                <select
                  className={'_input'}
                  style={{ cursor: 'pointer' }}
                  id="quater"
                  name="quater"
                  disabled={formData.month || formData.year === "2021-2022" ? true : false}
                  value={formData.frequency}
                  onChange={(t) => {
                    setformData({
                      ...formData,
                      frequency: t.target.value,
                    });
                  }}
                >
                  <option value="" selected>
                    All Quater
                  </option>

                  <option value={'quater2'}>{'April-May-June'}</option>
                  <option value={'quater3'}>{'July-August-September'}</option>
                  <option value={'quater4'}>{'October-November-December'}</option>
                  <option value={'quater1'}>{'January-February-March'}</option>
                </select>
                {/* {formDataError.class12thBoard && (
                                        <span className="text-danger">
                                            {formDataError.class12thBoard}
                                        </span>
                                    )} */}
              </div>
            </div>
          </div>
        </div>

        <div className="dashbord_container">
          <div className="">
            <div className="item_container">
              <div className="itemHead ">
                {/* <img src="/assets/dashbord/activeSSSUsers.png" alt="users" /> */}
                <div className="itemName">Active Users</div>
              </div>
              <div className="itemHead2 ">
                {' '}
                {numberWithCommas(typeof(dashbord?.activeSSSUsers?.count)!='number'?0:dashbord?.activeSSSUsers?.count + staticYearData?.activeUsers?.count)}
              </div>
            </div>
          </div>
          <div className="">
            <div className="item_container backColorL2 ">
              <div className="itemHead ">
                {/* <img src="/assets/dashbord/teachersTrained.png" alt="users" /> */}
                <div className="itemName backColor2">Teachers trained</div>
              </div>
              <div className="itemHead2 backColor2">
                {numberWithCommas(typeof(dashbord?.teachersTrained?.visitcount)!='number'?0:dashbord?.teachersTrained?.visitcount + staticYearData?.teacherTrained?.count)}
              </div>
            </div>
          </div>
          <div className="">
            <div className="item_container backColorL3">
              <div className="itemHead">
                {/* <img src="/assets/dashbord/sssKits.png" alt="users" /> */}
                <div className="itemName backColor3">SSS Kits distributed</div>
              </div>
              <div className="itemHead2 backColor3">{numberWithCommas(staticYearData?.kitsDistributed?.count)}</div>
            </div>
          </div>
          <div className="">
            <div className="item_container">
              <div className="itemHead">
                {/* <img src="/assets/dashbord/programAudit.png" alt="users" /> */}
                <div className="itemName">Program audit meetings</div>
              </div>
              <div className="itemHead2">
                {numberWithCommas(typeof(dashbord?.programAuditCount?.visitcount +dashbord?.schoolVisitsCount?.visitcount)!='number'?0:dashbord?.programAuditCount?.visitcount +dashbord?.schoolVisitsCount?.visitcount + staticYearData?.programMeeting?.count)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashbord_container  mt-4">
        {/* <div className=""> */}
        <div className="item_container ">
          <div className="itemNameHead ">
            {/* <img src="/assets/dashbord/programAudit.png" alt="users" /> */}
            <div className='_headName'> Resource Used</div>
          </div>
          
          <div className="itemHead colorBlueLight height20  BgColor1">
            <div className="itemName ">Total resources used</div>
          </div>
          <div className="itemHead colorBlueLight height20 BgColor1">
            {numberWithCommas(typeof(dashbord?.resourcesUsed?.resource_view_count +dashbord?.resourcesUsed?.course_completion_count)!='number'?0:dashbord?.resourcesUsed?.resource_view_count +dashbord?.resourcesUsed?.course_completion_count + staticYearData?.resourcesUsed?.totalResources)}
          </div>
          <div className="itemHead colorBlueLight height20 BgColor2">
            <div className="itemName ">Resources used/school</div>
          </div>
          <div className="itemHead colorBlueLight height20 BgColor2 borderRadius">{`${staticYearData?.resourcesUsed?.resourcesSchool + (dashbordDataSchool?.resourcesUsed?.resource_view_count + dashbordDataSchool?.resourcesUsed?.unique_course_count)/dashbordDataSchool.schoolImpacted?.count}`.slice(0,5) }</div>
        
        </div>
        {/* </div> */}
        {/* <div className=""> */}
        <div className="item_container backColorL2">
          <div className="itemNameHead backColor2">
            {/* <img src="/assets/dashbord/subjectResourceUsed.png" alt="users" /> */}
            <div className='_headName'>Subject Resource Used</div>
          </div>
          <div className="itemHead colorBlueLight BgColor1">
            <div className="itemName backColor2 ">English Foundation</div>
          </div>
          <div className="itemHead backColor2  colorBlueLight BgColor1">
            {
             numberWithCommas( dashbord?.resourcesUsed?.resources_used_by_subject
                ?.English_Foundation + staticYearData?.resourcesUsed?.english)
            }{' '}
            <span className="persantage">
              {ratingSSS(
                typeof(dashbord?.resourcesUsed?.resources_used_by_subject?.English_Foundation)!='number'?0:dashbord?.resourcesUsed?.resources_used_by_subject?.English_Foundation + staticYearData?.resourcesUsed?.english,typeof(dashbord?.resourcesUsed?.resource_view_count)!='number'?0:dashbord?.resourcesUsed?.resource_view_count + staticYearData?.resourcesUsed?.totalResources
              )}
              %
            </span>
          </div>
          <div className="itemHead colorBlueLight">
            <div className="itemName backColor2 colorBlueLight BgColor2">Math</div>
          </div>
          <div className="itemHead backColor2 colorBlueLight BgColor2">
          {numberWithCommas(typeof(dashbord?.resourcesUsed?.resources_used_by_subject?.Math)!='number'?0:dashbord?.resourcesUsed?.resources_used_by_subject.Math + staticYearData?.resourcesUsed?.math)}{' '}
            <span className="persantage">
            {ratingSSS(
                typeof(dashbord?.resourcesUsed?.resources_used_by_subject?.Math)!='number'?0:dashbord?.resourcesUsed?.resources_used_by_subject?.Math + staticYearData?.resourcesUsed?.math,typeof(dashbord?.resourcesUsed?.resource_view_count)!='number'?0:dashbord?.resourcesUsed?.resource_view_count + staticYearData?.resourcesUsed?.totalResources
              )}
              %
            </span>
          </div>
          <div className="itemHead colorBlueLight BgColor3">
            <div className="itemName backColor2 ">Other subjects</div>
          </div>
          <div className="itemHead backColor2 colorBlueLight border_bottom_radius BgColor3">
            {' '}
            {
          numberWithCommas( dashbord?.resourcesUsed?.resources_used_by_subject?.English +
            dashbord?.resourcesUsed?.resources_used_by_subject?.English_Textbooks +
            dashbord?.resourcesUsed?.resources_used_by_subject?.Science +
           dashbord?.resourcesUsed?.resources_used_by_subject?.Skill +
            dashbord?.resourcesUsed?.resources_used_by_subject?.Training +
            dashbord?.resourcesUsed?.resources_used_by_subject?.Introduction  + staticYearData?.resourcesUsed?.other)
            }{' '}
            <span className="persantage">
              {ratingSSS(
                 dashbord?.resourcesUsed?.resources_used_by_subject?.English + staticYearData?.resourcesUsed?.other +
                 dashbord?.resourcesUsed?.resources_used_by_subject?.English_Textbooks +
                 dashbord?.resourcesUsed?.resources_used_by_subject?.Science +
                 dashbord?.resourcesUsed?.resources_used_by_subject?.Skill +
                 dashbord?.resourcesUsed?.resources_used_by_subject?.Training +
                 dashbord?.resourcesUsed?.resources_used_by_subject?.Introduction ,
               dashbord?.resourcesUsed?.resource_view_count + staticYearData?.resourcesUsed?.totalResources
              )}
              %
            </span>
          </div>
        </div>
        {/* </div> */}
        {/* <div className=""> */}
        <div className="item_container backColorL2">
          <div className="itemNameHead backColor2">
          {/* <img src="/assets/dashbord/classResourceUsed.png" alt="users" /> */}
            <div className='_headName' >Class Resource Used</div>
          </div>
          <div className="itemHead colorBlueLight BgColor1">
            <div className="itemName backColor2">FLN (KG, Class 1-2-3)</div>
          </div>
          <div className="itemHead colorBlueLight backColor2 BgColor1">
            {
             numberWithCommas( dashbord?.resourcesUsed?.resources_used_by_department?.KG +
              dashbord?.resourcesUsed?.resources_used_by_department?.FLN__Class_1 + staticYearData?.resourcesUsed?.fln +
              dashbord?.resourcesUsed?.resources_used_by_department?.FLN__Class_2 +
              dashbord?.resourcesUsed?.resources_used_by_department?.FLN__Class_3)
              }
            <span className="persantage">
              {ratingSSS(
                dashbord?.resourcesUsed?.resources_used_by_department?.KG +
                dashbord?.resourcesUsed?.resources_used_by_department?.FLN__Class_1 + staticYearData?.resourcesUsed?.fln +
                dashbord?.resourcesUsed?.resources_used_by_department?.FLN__Class_2 +
                dashbord?.resourcesUsed?.resources_used_by_department?.FLN__Class_3,
                dashbord?.resourcesUsed?.resource_view_count + staticYearData?.resourcesUsed?.totalResources
              )}
              %
            </span>
          </div>
          <div className="itemHead colorBlueLight BgColor2">
            <div className="itemName backColor2">Class 4-5</div>
          </div>
          <div className="itemHead colorBlueLight backColor2 BgColor2">
            {
            numberWithCommas( dashbord?.resourcesUsed?.resources_used_by_department?.Class_4 +  staticYearData?.resourcesUsed?.class4_5 +
             dashbord?.resourcesUsed?.resources_used_by_department?.Class_5)
              }
            <span className="persantage">
              {ratingSSS(
               dashbord?.resourcesUsed?.resources_used_by_department?.Class_4 +  staticYearData?.resourcesUsed?.class4_5 +
               dashbord?.resourcesUsed?.resources_used_by_department?.Class_5,
                dashbord?.resourcesUsed?.resource_view_count +  staticYearData?.resourcesUsed?.totalResources
              )}
              %
            </span>
          </div>
          <div className="itemHead colorBlueLight BgColor3">
            <div className="itemName backColor2"> Class 6-8</div>
          </div>
          <div className="itemHead colorBlueLight backColor2 border_bottom_radius BgColor3">
            {
              numberWithCommas(dashbord?.resourcesUsed?.resources_used_by_department?.Class_6 +
             dashbord?.resourcesUsed?.resources_used_by_department?.Class_7 +
              dashbord?.resourcesUsed?.resources_used_by_department?.Class_8)
              }
            <span className="persantage">
              {ratingSSS(
                dashbord?.resourcesUsed?.resources_used_by_department?.Class_6 +
               dashbord?.resourcesUsed?.resources_used_by_department?.Class_7 +
                dashbord?.resourcesUsed?.resources_used_by_department?.Class_8,
               dashbord?.resourcesUsed?.resource_view_count
              )}
              %
            </span>
          </div>
        </div>
        {/* </div> */}

        {/* <div className=""> */}
        <div className="item_container ">
          <div className="itemNameHead ">
          {/* <img src="/assets/dashbord/otherResourceUsed.png" alt="users" /> */}
            <div className='_headName '>Other Resource Used</div>
          </div>
          <div className="itemHead colorBlueLight BgColor1 Elearning">
            <div className="itemName " style={{marginTop:"60px"}}>E-learning courses completed</div>
          </div>
          <div className="itemHead  colorBlueLight BgColor1 elearningCard">
            {numberWithCommas(dashbord?.resourcesUsed?.resources_used_by_department?.E_learning + staticYearData?.resourcesUsed?.eLearning )}
          </div>
        </div>
        {/* </div> */}

        {/* <div className="_item_2">
          <div className="item_head">Top Performers</div>
          <div className="item_head2 red border_bottom font_size_12">
            Nuh (2076)
          </div>
          <div className="item_head2 border_bottom font_size_12">
            Yamunanagar (1249)
          </div>
          <div>
            <div className="item_head2 font_size_12 ">Charkhi Dadri (1189)</div>
            <div className="head3"> Basis: resource used /schools</div>
          </div>
        </div> */}
        
       
          <div className="item_container ">
            <div className="itemNameHead ">
            {/* <img src="/assets/dashbord/sssAssessment.png" alt="users" /> */}
              <div className='_headName'> Assessment</div>
            </div>
            <div className="itemHead colorBlueLight BgColor1">
              <div className="itemName ">Total Assessments</div>
            </div>
            <div className="itemHead colorBlueLight BgColor1 ">
              {numberWithCommas(typeof(dashbord.assessmentUsed?.assessments_taken)!='number'?0:dashbord.assessmentUsed?.assessments_taken + staticYearData?.assessmentUsed?.totalResources)}
            </div>
            <div className="itemHead colorBlueLight BgColor2">
              <div className="itemName "> assessments/school</div>
            </div>
            <div className="itemHead colorBlueLight BgColor2">{ `${staticYearData?.assessmentUsed?.resourcesSchool + (dashbordDataSchool?.assessmentUsed?.assessments_taken )/dashbordDataSchool.schoolImpacted?.count }`.slice(0,5)}</div>
            <div className="itemHead colorBlueLight BgColor3">
              <div className="itemName "> No of children</div>
            </div>
            <div className="itemHead colorBlueLight  border_bottom_radius BgColor3 ">
              {' '}
              {numberWithCommas(typeof(dashbord.assessmentUsed.unique_student_count)!='number'?0:dashbord.assessmentUsed.unique_student_count + staticYearData?.assessmentUsed?.children)}
            </div>
          </div>
     
       
          <div className="item_container backColorL2">
            <div className="itemNameHead backColor2">
            {/* <img src="/assets/dashbord/subjectAssessment.png" alt="users" /> */}
             <div className='_headName'> Subject Assessment </div>
            </div>
            <div className="itemHead colorBlueLight BgColor1">
              <div className="itemName backColor2  ">English</div>
            </div>
            <div className="itemHead colorBlueLight backColor2 BgColor1">
              {numberWithCommas(dashbord?.assessmentUsed?.resources_used_by_subject?.English +  staticYearData?.assessmentUsed?.english)}{' '}
              <span className="persantage">
                {ratingSSS(
                  dashbord?.assessmentUsed?.resources_used_by_subject?.English +  staticYearData?.assessmentUsed?.english,
                  dashbord?.assessmentUsed?.assessments_taken +  staticYearData?.assessmentUsed?.totalResources
                )}
                %
              </span>
            </div>
            <div className="itemHead colorBlueLight">
              <div className="itemName backColor2 BgColor2">Math</div>
            </div>
            <div className="itemHead colorBlueLight backColor2 BgColor2">
              {numberWithCommas(dashbord?.assessmentUsed?.resources_used_by_subject?.Math + staticYearData?.assessmentUsed?.math)}{' '}
              <span className="persantage">
                {ratingSSS(
                  dashbord?.assessmentUsed?.resources_used_by_subject?.Math + staticYearData?.assessmentUsed?.math,
                  dashbord?.assessmentUsed?.assessments_taken + staticYearData?.assessmentUsed?.totalResources
                )}
                %
              </span>
            </div>
            <div className="itemHead colorBlueLight BgColor3">
              <div className="itemName backColor2 ">Other subjects</div>
            </div>
            <div className="itemHead colorBlueLight backColor2 border_bottom_radius BgColor3">
              {
               numberWithCommas( dashbord?.assessmentUsed?.resources_used_by_subject?.Hindi +
                dashbord?.assessmentUsed?.resources_used_by_subject?.Science +
                dashbord?.assessmentUsed?.resources_used_by_subject?.EVS + staticYearData?.assessmentUsed?.other)
                }{' '}
              <span className="persantage">
                {ratingSSS(
                  dashbord?.assessmentUsed?.resources_used_by_subject?.Hindi +
                  dashbord?.assessmentUsed?.resources_used_by_subject?.Science +
                  dashbord?.assessmentUsed?.resources_used_by_subject?.EVS + staticYearData?.assessmentUsed?.math,
                  dashbord?.assessmentUsed?.assessments_taken + staticYearData?.assessmentUsed?.totalResources
                )}
                %
              </span>
            </div>
          </div>
       
          <div className="item_container backColorL3">
            <div className="itemNameHead backColor3">
            {/* <img src="/assets/dashbord/classAssessment.png" alt="users" /> */}
              <div className='_headName'>Class Assessment </div>
            </div>
            <div className="itemHead colorBlueLight BgColor1">
              <div className="itemName backColor3">Class 1-3</div>
            </div>
            <div className="itemHead colorBlueLight backColor3 BgColor1">
              { 
              numberWithCommas( dashbord?.assessmentUsed?.resources_used_by_department?.class_1 +
                dashbord?.assessmentUsed?.resources_used_by_department?.class_2 +
               dashbord?.assessmentUsed?.resources_used_by_department?.class_3 + staticYearData?.assessmentUsed?.fln)
                }
              <span className="persantage">
                {ratingSSS(
                 dashbord?.assessmentUsed?.resources_used_by_department?.class_1 +
                  dashbord?.assessmentUsed?.resources_used_by_department?.class_2 +
                 dashbord?.assessmentUsed?.resources_used_by_department?.class_3 + staticYearData?.assessmentUsed?.fln,
                 dashbord?.assessmentUsed?.assessments_taken + staticYearData?.assessmentUsed?.totalResources
                )}
                %
              </span>
            </div>
            <div className="itemHead colorBlueLight BgColor2 ">
              <div className="itemName backColor3">Class 4-5</div>
            </div>
            <div className="itemHead colorBlueLight backColor3 BgColor2">
              {
                numberWithCommas(dashbord?.assessmentUsed?.resources_used_by_department?.class_4 +
                dashbord?.assessmentUsed?.resources_used_by_department?.class_5 + staticYearData?.assessmentUsed?.class4_5)
                }{' '}
              <span className="persantage">
                {ratingSSS(
                  dashbord?.assessmentUsed?.resources_used_by_department?.class_4 +
                 dashbord?.assessmentUsed?.resources_used_by_department?.class_5 + staticYearData?.assessmentUsed?.class4_5,
                  dashbord?.assessmentUsed?.assessments_taken + staticYearData?.assessmentUsed?.totalResources
                )}
                %
              </span>
            </div>
            <div className="itemHead colorBlueLight BgColor3">
              <div className="itemName backColor3"> Class 6-8</div>
            </div>
            <div className="itemHead colorBlueLight backColor3 border_bottom_radius BgColor3">
              {
               numberWithCommas( dashbord?.assessmentUsed?.resources_used_by_department?.class_6 +
                dashbord?.assessmentUsed?.resources_used_by_department?.class_7 +
                dashbord?.assessmentUsed?.resources_used_by_department?.class_8)
                }
              <span className="persantage">
                {ratingSSS(
                  dashbord?.assessmentUsed?.resources_used_by_department?.class_6 +
                  dashbord?.assessmentUsed?.resources_used_by_department?.class_7 +
                  dashbord?.assessmentUsed?.resources_used_by_department?.class_8,
                  dashbord?.assessmentUsed?.assessments_taken
                )}
                %
              </span>
            </div>
          </div>
       
      </div>
     {!Loading.loading  && topDistrict.length > 0   && (

       <Topdistrict formData={formData} selectedOption={selectedOption}  topBlocks={topBlock} topDistrict={topDistrict} />
     )}
      {!Loading.loading  && topBlock.length > 0  && (

<Topdistrict formData={formData} selectedOption={selectedOption}  topBlocks={topBlock} topDistrict={topDistrict} />
)}
     { dashbord?.schoolAuditCount?.P1 ? (

   
      <>
      <div className='head_container' >

       <div className='w-100' > 
            <div className="head_program"> C. Program Benchmark Based On Schools Audited</div>
            <div className='text-center'>
              <div className='borderLine2' ></div>
            </div>
            <div className='d-flex' >
            <div style={{marginRight:'30px'}} className='mr-3 stateDecription'> State : {selectedOption.map((item)=>(`${item.label} , ` ))}  </div>
            <div className='stateDecription'> {formData.districtName && ' District :'+ formData.districtName  } </div>
            </div>
          </div>
      </div>
      <div className="dashbord_container_P1 ">
        <div>
          <div className="program_head">P1 (Teacher Trained)</div>

          <div
            className={`c100 p${percentage(
              typeof(dashbord?.schoolAuditCount?.P1)!='number'?0:dashbord?.schoolAuditCount?.P1,
              typeof(dashbord?.schoolAuditCount?.P1_district)!='number'?0:dashbord?.schoolAuditCount?.P1_district
            )} center`}
          >
            <span>
              {rating(
                typeof(dashbord?.schoolAuditCount?.P1)!='number'?0:dashbord?.schoolAuditCount?.P1,
                typeof(dashbord?.schoolAuditCount?.P1_district)!='number'?0:dashbord?.schoolAuditCount?.P1_district
              )}
            </span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <div className="program_sub"> out of total score of 5</div>
        </div>

        <div>
          <div className="program_head">P2 (TLM Usages)</div>

          <div
            className={`c100 orange p${percentage(
              typeof(dashbord.schoolAuditCount.P2)!='number'?0:dashbord.schoolAuditCount.P2,
              typeof(dashbord.schoolAuditCount.P2_district)!='number'?0:dashbord.schoolAuditCount.P2_district
            )} center`}
          >
            <span>
              {rating(
                typeof(dashbord.schoolAuditCount.P2)!='number'?0:dashbord.schoolAuditCount.P2,
                typeof(dashbord.schoolAuditCount.P2_district)!='number'?0:dashbord.schoolAuditCount.P2_district
              )}
            </span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <div className="program_sub"> out of total score of 5</div>
        </div>
        <div>
          <div className="program_head">P3 (Teachers Diary Used)</div>

          <div
            className={`c100 green p${percentage(
              typeof(dashbord?.schoolAuditCount?.P3)!='number'?0:dashbord.schoolAuditCount.P3,
              typeof(dashbord.schoolAuditCount?.P3_district)!='number'?0:dashbord.schoolAuditCount.P3_district
            )} center`}
          >
            <span>
              {rating(
                typeof(dashbord.schoolAuditCount.P3)!='number'?0:dashbord.schoolAuditCount.P3,
                typeof(dashbord.schoolAuditCount.P3_district)!='number'?0:dashbord.schoolAuditCount.P3_district
              )}
            </span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <div className="program_sub"> out of total score of 5</div>
        </div>
        <div>
          <div className="program_head">P4 (Work Book Used)</div>

          <div
            className={`c100 orange p${percentage(
              typeof(dashbord.schoolAuditCount.P4)!='number'?0:dashbord.schoolAuditCount.P4,
              typeof(dashbord.schoolAuditCount.P4_district)!='number'?0:dashbord.schoolAuditCount.P4_district
            )} center`}
          >
            <span>
              {rating(
                typeof(dashbord.schoolAuditCount.P4)!='number'?0:dashbord.schoolAuditCount.P4,
                typeof(dashbord.schoolAuditCount.P4_district)!='number'?0:dashbord.schoolAuditCount.P4_district
              )}
            </span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <div className="program_sub"> out of total score of 5</div>
        </div>
        <div>
          <div className="program_head">P5 (Sequence Followed)</div>

          <div
            className={`c100 p${percentage(
              typeof(dashbord.schoolAuditCount.P5)!='number'?0:dashbord.schoolAuditCount.P5,
              typeof(dashbord.schoolAuditCount.P5_district)!='number'?0:dashbord.schoolAuditCount.P5_district
            )} center`}
          >
            <span>
              {rating(
                typeof(dashbord.schoolAuditCount.P5)!='number'?0:dashbord.schoolAuditCount.P5,
                typeof(dashbord.schoolAuditCount.P5_district)!='number'?0:dashbord.schoolAuditCount.P5_district
              )}
            </span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <div className="program_sub"> out of total score of 5</div>
        </div>
      </div>
      </>  ) : null}
    </>
  );
};

export default Section2;
// || topBlock.length > 0