import React from 'react';
// import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';

const DownloardPdf = ({state,district,block,school,session}) => {
  return (
    <>
      <div className="_input_container m-2 mb-0 mt-0">
        <Link target="_blank"
          to={`/previewPdf?state=${state}&district=${district}&block=${block}&session=${session}&school=${school}`}
          className="btn btn-outline-success px-2"
        >
          PDF Preview 
        </Link>
      </div>
    </>
  );
};

export default DownloardPdf;
