import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import configKeys from '../config/keys';
import "./css/progressSmall.css"
import "./css/progress.css"
import "./css/style.css"
import LoadingSpinner from "../components/LoadingSpinner";
import BlockDashboard from "..//components/BlockDashboard";

const TopBlocks = () => {
    const location = useLocation().search
    const state_id = new URLSearchParams(location).get("state_id")
    const district_id = new URLSearchParams(location).get("district_id")
    const session = new URLSearchParams(location).get("session")
    const [topBlocks, setTopBlocks] = useState([])
    const [currentDistrict, setcurrentDistrict] = useState([]);
    const [topSchools, setTopSchools] = useState([])
    const [districtAverage, setdistrictAverage] = useState('')
    const [upperPrimaryStatus, setupperPrimaryStatus] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [topBlocksLoading, setTopBlocksLoading] = useState({
        loading: false,
        success: '',
        error: '',
      })
      useEffect(() => {
        getBlocks()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    
    const  getBlocks = async ()=>{
        try {
            setTopBlocksLoading({
                loading: true,
                success: '',
                error: '',
            });

            setIsLoading(true);
            const { data } = await axios.get(`${configKeys.SERVER_URL}/v2/topBlockList?state_id=${state_id}&district_id=${district_id}&session=${session}`);
            setTopBlocks(data.data.topBlocks);
            setTopSchools(data.data.topSchools);
            setdistrictAverage(data.data.average)
            setupperPrimaryStatus(data.data.upperPrimarySTVSchools)
            setcurrentDistrict(data.data.stateData.name.toLowerCase()+" - District "+data.data.districtData.name.toLowerCase())
            setTopBlocksLoading({
                loading: false,
                success: '',
                error: '',
            });
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            setTopBlocksLoading({
                loading: false,
                success: '',
                error: error.message,
            });
            setIsLoading(false);
        }
    }


    return (
    <div>
        {isLoading ? <LoadingSpinner /> : <BlockDashboard 
            currentDistrict={currentDistrict} 
            districtAverage={districtAverage} 
            topBlocks={topBlocks} 
            topSchools={topSchools}
            state_id={state_id}
            district_id={district_id}
            selectedSession={session}
            upperPrimaryStatus={upperPrimaryStatus}
            topBlocksLoading={topBlocksLoading} />} 

    </div>
    )
}

export default TopBlocks