import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {  useHistory } from 'react-router-dom';
import {
  isInputEmailValid,
  isInputEmpty,
  isInputPasswordValid,
} from '../constants/inputValidaation';
import { register_user } from '../redux/actions/userActions';
import './css/login.css';

const Register = (props) => {
  const register = props.register_user;
  const user = props.user;
  const mHistory = useHistory();
  //   const [submitSuccess, setSubmitSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    admintype:''
  });
  const [requestAuth, setRequestAuth] = useState({
    success: '',
    error: '',
    loading: false,
  });

  const [formDataError, setFormDataError] = useState({
    email: '',
    password: '',
    name: '',
    admintype:''
  });

  const token = JSON.parse(localStorage.getItem('user'));
  console.log(token);
  useEffect(() => {
    if (!user || user === null) {
      return mHistory.push('/login');
    }
    if (!token) return mHistory.push('/login');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  
  const validateForm = () => {
    let hasError = false;

    let tempError = {
      email: '',
      password: '',
      name: '',
      admintype:''
    };

    tempError.email = isInputEmailValid(formData.email);
    if (tempError.email !== '') {
      hasError = true;
    }
    tempError.name = isInputEmpty(formData.name);
    if (tempError.name !== '') {
      hasError = true;
    }
    tempError.admintype = isInputEmpty(formData.admintype);
    if (tempError.admintype !== '') {
      hasError = true;
    }
    tempError.password = isInputPasswordValid(formData.password);
    if (tempError.password !== '') {
      hasError = true;
    }

    setFormDataError({
      ...formDataError,
      ...tempError,
    });

    return hasError;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      return;
    }

    try {
      setRequestAuth({
        loading: true,
        error: '',
        success: '',
      });

      const res = await register(
        formData.name,
        formData.email,
        formData.password,
        formData.admintype,
        token
      );
      console.log(res);
      setRequestAuth({
        loading: false,
        error: '',
        success: 'Login Successfully',
      });
      setTimeout(() => {
        setFormData({
          email: '',
          password: '',
          name: '',
          admintype:''
        })
        setRequestAuth({
          success: '',
          error: '',
          loading: false,
        })
      }, 3000);

    } catch (error) {
      console.log(error);
      setRequestAuth({
        loading: false,
        error: error?.response?.data?.message,
        success: '',
      });
    }
  };
  return (
    <div className="text-center">
      <div className="login_container">
        <div className="login_head">Create an Account !!</div>
        <form className="text-center">
          <div className="form-group text-left" style={{ textAlign: 'left' }}>
            <label>Enter Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your Name"
              className="form-control p-2  EmailInput"
              value={formData.name}
              onChange={(t) => {
                setFormData({
                  ...formData,
                  name: t.target.value,
                });
              }}
            />
            {formDataError.name && (
              <span className="text-danger">{formDataError.name}</span>
            )}
          </div>
          <div className="form-group text-left" style={{ textAlign: 'left' }}>
            <label>Enter Email ID</label>
            <input
              type="email"
              name="Email"
              placeholder="Enter your Email"
              className="form-control p-2  EmailInput"
              value={formData.email}
              onChange={(t) => {
                setFormData({
                  ...formData,
                  email: t.target.value,
                });
              }}
            />
            {formDataError.email && (
              <span className="text-danger">{formDataError.email}</span>
            )}
          </div>
          <div className={`Container`}>
            <div>
              <input
                type={showPassword ? 'text' : 'password'}
                name={'password'}
                id={'password'}
                className="Input"
                value={formData.password}
                placeholder={'Please enter your password'}
                onChange={(e) => {
                  // setShowBtn(true);
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  });
                }}
              />
              <img
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                src={
                  showPassword
                    ? '/assets/icons/iconPasswordShow.svg'
                    : '/assets/icons/iconPasswordHide.svg'
                }
                alt={showPassword ? 'Show Password' : 'Hide Password'}
              />
            </div>
          </div>

          <div className="form-group text-left radio_container">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="admin"
                id="admin"
                checked={formData.admintype === 'admin' ? true : false }
                value={formData.admintype === 'admin' ? true : false }
                onChange={e=>{
                  if(e.target.checked) setFormData({...formData,admintype:'admin'})
                }}
              />
              <label class="form-check-label" for="flexRadioDefault1">
              Admin 
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="standard_user"
                id="standard_user"
                checked={formData.admintype === 'standard_user' ? true : false }
                value={formData.admintype === 'standard_user' ? true : false }
                onChange={e=>{
                  if(e.target.checked) setFormData({...formData,admintype:'standard_user'})
                }}
              />
              <label class="form-check-label" for="flexRadioDefault2">
              Standard User
              </label>
            </div>
          </div>
          { formDataError.admintype && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              Plese Select one filed
            </div>
          )}
          {(formDataError.email || formDataError.password) && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              Invalid password or Email
            </div>
          )}

          {requestAuth.loading && (
            <div className="rounded mt-1 mb-1 p-1 border border-info text-center text-info">
              Loading...
            </div>
          )}
          {requestAuth.success && (
            <div className="rounded mt-1 mb-1 p-1 border border-success text-center text-success">
              {requestAuth.success}
            </div>
          )}
          {requestAuth.error && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              {requestAuth.error}
            </div>
          )}

          <div className={'__btnSubmit_container'}>
            {formData.password !== '' &&
            formData.name !== '' &&
            formData.email !== '' ? (
              <button
                className={`login_sectionSubmit_button`}
                type="button"
                onClick={() => onSubmit()}
              >
                Register
              </button>
            ) : (
              <button
                className={`login_sectionSubmit_disabledButton`}
                disabled
                type="button"
                onClick={() => onSubmit()}
              >
                Register
              </button>
            )}
          </div>
          {/* <Link to="/login">Already Have An Account</Link> */}
        </form>
      </div>
    </div>
  );
};

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
  };
};

export default connect(mapStateStore, { register_user })(Register);
