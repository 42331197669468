import React from "react";
import {VictoryPie, VictoryLabel} from 'victory';

const ProgressGraph = (props) => {
  const width = (props.width) ? props.width : 300;
  const halfwidth = width/2;
  const padding = (props.width) ? 15 : 15
  const innerRadius = (props.width) ? 60 : 60
  const labelRadius = (props.width) ? 80 : 80
  return (
    <svg width={width} height={width}>
    <VictoryPie 
        standalone={false}
        padAngle={0}
        // used to hide labels
        labelComponent={<span/>}
        innerRadius={innerRadius}
        labelRadius={labelRadius}
        padding={padding}
        width={width} height={width}
        data={[{'key': "", 'y': (props.isNA && props.isNA === 'YES') ? 0 : props.progress}, {'key': "", 'y': (props.isNA && props.isNA === 'YES') ? 100 : (100-props.progress)} ]}
        colorScale={[props.color ? props.color : "#EC4824", "#EEEEEE" ]}
        style={{ labels: { fontSize: 20, fill: "white" } }}
    />
    <VictoryLabel
      textAnchor="middle"
      style={{ fontSize: 25, fontWeight: 'Bold', fill: '#7B7B7B' }}
      x={halfwidth} y={halfwidth}
      text={(props.isNA && props.isNA === 'YES') ? 'NA' : props.progress+"%"}
    />
    </svg>
  );
}
export default ProgressGraph