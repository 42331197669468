import React from 'react';
// import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';

const DownloardPdf = ({onPdfClick}) => {
  return (
    <>
      <div className="" style={{margin:"13px"}} >
        <Link
        onClick={onPdfClick}
        to={"/previewPdf"}
          className="btn btn-outline-success px-2"
        //   document={<PdfDocument data={movieDetails} />}
          filename="movielist.pdf"  
        >
          PDF Preview 
        </Link>
      </div>
    </>
  );
};

export default DownloardPdf;
